<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active">數位教具</li>
              <li class="breadcrumb-item active" aria-current="page">AR虛擬角色體驗</li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2 class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3">
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold">AR虛擬角色體驗</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-5 g-2">
        <h3 class="pt-2 pb-2 fw-bold">
          <i class="fa-solid fa-ellipsis-vertical me-3 text-primary"></i>操作說明
        </h3>
        <p class="lh-lg font-1-2em">
          國立臺灣圖書館和東南科技大學合作開發小型擴增實境（AR）特效應用程式。<br />
          1.使用手機掃描下方QR code連結<br />
          2.點擊計劃案網站上的「首頁」<br />
          3.將手機螢幕對準主視覺圖像，即可啟動AR體驗<br />
          您也可以搭配計畫案主視覺貼紙，與AR虛擬角色進行更有趣的互動體驗！<br />
          欲索取計畫案主視覺貼紙，請關注我們的Facebook粉絲團，參與我們舉辦的活動，即可免費索取。
        </p>
        <div class="col-12 col-lg-6 text-center mt-3 mt-lg-5">
          <h3 class="pt-2 pb-2 fw-bold">
            <i class="fa-solid fa-face-laugh me-3 text-primary"></i>日本總督
          </h3>
          <img class="w-50" src="/images/home/AR/AR日本總督.jpg" alt="" />
        </div>
        <div class="col-12 col-lg-6 text-center mt-3 mt-lg-5">
          <h3 class="pt-2 pb-2 fw-bold">
            <i class="fa-solid fa-face-laugh me-3 text-primary"></i>釜山丸
          </h3>
          <img class="w-50" src="/images/home/AR/AR釜山丸.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  methods: {
    getPhoto(index) {
      return "/files/poker/P (" + index + ").png";
    },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
